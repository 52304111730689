//////////////////////////////////////////////////////////////////
///// Menu  //////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

import $ from 'jquery';

export default function header() {
  const toggleMenu = $('.header__menu-toggle > .hamburger');
  const header = $('.header');
  const navFlyout = $('.navFlyout');
  const navJumpmarks = $('.navFlyout__menu .jumper a');
  /* eslint-disable-next-line no-unused-vars */
  let flyoutOpen = false;

  const closeFlyout = function () {
    toggleMenu.removeClass('is-active');
    navFlyout.removeClass('is-active');
    header.removeClass('is-open');
    $('html').removeClass('menu-open');
    flyoutOpen = false;
  };

  const openFlyout = function () {
    toggleMenu.addClass('is-active');
    navFlyout.addClass('is-active');
    header.addClass('is-open');
    $('html').addClass('menu-open');
    setTimeout(function () {
      flyoutOpen = true;
    }, 800);
  };

  navJumpmarks.each(function () {
    const $this = $(this);
    console.log($this)
    $this.click(function (e) {
      //e.preventDefault();
      closeFlyout();
    });
  });

  toggleMenu.click(function (e) {
    e.preventDefault();
    const $this = $(this);
    if ($this.hasClass('is-active')) {
      closeFlyout();
    } else {
      openFlyout();
    }
  });

  // Close menu on esc
  $(document).keyup(function (e) {
    if (e.keyCode === 27) {
      closeFlyout();
    }
  });

  // show menu toggle when navigation scrolls out
  const menutoggleTrigger = 150;

  window._isScrolled = false;
  $(window).on('scroll', false, function () {
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;

    if (scrollTop > menutoggleTrigger && !window._isScrolled) {
      window._isScrolled = true;
      header.addClass('out-of-view');
    } else if (scrollTop <= menutoggleTrigger && window._isScrolled) {
      window._isScrolled = false;
      header.removeClass('out-of-view');
    } else if (flyoutOpen) {
      closeFlyout();
    }
  });
}
