/**
 * Swiper
 */
import Swiper from 'swiper';
import { MediaQuery } from 'foundation-sites/js/entries/plugins/foundation.util.mediaQuery';
import $ from 'jquery';
import merge from 'lodash/merge';

export default function carousel() {
  const swiperInstances = [];
  const selectorCarousel = $('.carousel');

  if (!selectorCarousel.length) { return; }

  // breakpoint where swiper will be destroyed
  // and switches to a dual-column layout
  const isSmallDevice = () => {
    return MediaQuery.current === 'small';
  };

  const baseOptions = ($parent, slidesOffsetBefore) => {
    return {
      keyboardControl: true,
      keyboard: {
        enabled: true,
        onlyInViewport: true
      },
      mousewheelControl: true,
      mousewheelForceToAxis: true,
      watchOverflow: true,
      watchSlidesVisibility: true,
      loop: false,
      slidesPerView: 'auto',
      //spaceBetween: 10,
      observer: true,
      navigation: {
        nextEl: $('.swiper-button-next', $parent),
        prevEl: $('.swiper-button-prev', $parent)
      },
      pagination: {
        el: $('.swiper-pagination', $parent),
        type: 'bullets',
        clickable: true
      },
      slidesOffsetBefore: slidesOffsetBefore,
      speed: 500,
      breakpoints: {
        768: {
          slidesPerView: 3,
          spaceBetween: 10
        },
        1440: {
          slidesPerView: 3
        }
      }
    };
  };

  const teamSlider = () => {
    return {
      slidesPerView: 4,
      breakpoints: {
        1440: {
          slidesPerView: 3
        }
      }
    };
  };

  const flipcardSlider = () => {
    return {
      slidesPerView: 4,
      spaceBetween: 20,
      breakpoints: {
        768: {
          slidesPerView: 2
        },
        1024: {
          slidesPerView: 2
        },
        1600: {
          slidesPerView: 3
        }
      }
    };
  };

  const imageSlider = () => {
    return {
      slidesPerView: 3,
      breakpoints: {
        768: {
          slidesPerView: 4
        },
        1600: {
          slidesPerView: 5
        }
      }
    };
  };

  const horizontalSlider = () => {
    return {
      slidesPerView: 3,
      breakpoints: {
        768: {
          slidesPerView: 1
        },
        1440: {
          slidesPerView: 3
        }
      }
    };
  };

  const createSwiperObject = function (
    $parent,
    index,
    type,
    slidesOffsetBefore
  ) {
    let optionsSwiper = baseOptions($parent, slidesOffsetBefore);
    if (type === 'team') {
      optionsSwiper = merge(optionsSwiper, teamSlider($parent));
    }

    if (type === 'images') {
      optionsSwiper = merge(optionsSwiper, imageSlider($parent));
    }

    if (type === 'horizontal') {
      optionsSwiper = merge(optionsSwiper, horizontalSlider($parent));
    }

    if (type === 'flipcard') {
      optionsSwiper = merge(optionsSwiper, flipcardSlider($parent));
    }

    swiperInstances[index] = new Swiper(
      $('.swiper-container', $parent),
      optionsSwiper
    );
  };

  const calculateStartPosition = () => {
    const mainContentWidth = $('main').width();
    const wrapperMaxWidth = 1444;
    let startPosition = 0;

    if (mainContentWidth > wrapperMaxWidth) {
      startPosition = (mainContentWidth - wrapperMaxWidth) / 2 + 20;
      /* eslint-disable-next-line no-console */
      //console.log(startPosition);
    }
    return startPosition;
  };

  const enableSwiper = () => {
    selectorCarousel.each(function (index) {
      const $this = $(this);
      const carouselType = $this.data('type');
      //console.log(carouselType)
      const slidesOffsetBefore = calculateStartPosition();

      $('.swiper-container', $this).addClass('swiper-' + index);

      switch (carouselType) {
        case 'horizontal':
          createSwiperObject($this, index, 'horizontal', slidesOffsetBefore);
          break;
        case 'team':
          createSwiperObject($this, index, 'team', slidesOffsetBefore);
          break;
        case 'images':
          createSwiperObject($this, index, 'images', slidesOffsetBefore);
          break; 
        case 'flipcard':
          createSwiperObject($this, index, 'flipcard', slidesOffsetBefore);
          break;
        default:
          // eslint-disable-next-line no-console
          console.error(`carousel-type [${carouselType}] is not supported.`);
      }
    });
  };

  const breakpointChecker = () => {
    selectorCarousel.each(function (index) {
      const $this = $(this);
      const carouselType = $this.data('type');

      // destroy carousel for non-small devices
      if (isSmallDevice()) {
        if (swiperInstances[index]) {
          swiperInstances[index].destroy(true, true);
          swiperInstances[index] = void 0;
          $('.swiper-slide', $this).removeAttr('style');
        }
      }

      // create carousel for large breakpoints
      else if (!swiperInstances[index]) {
        createSwiperObject($this, index, carouselType);
      }
    });
  };

  enableSwiper();

  // keep an eye on viewport size changes
  $(window).on('changed.zf.mediaquery', breakpointChecker);

  // kickstart
  breakpointChecker();

  // return Promise.resolve({
  //   destroy: () => {
  //     swiperInstances.forEach((instance) => instance.destroy(true, true));
  //     swiperInstances.length = 0;
  //   }
  // });
}
