/**
 * Entry-point for the global dependencies
 */

/*
 * Style imports
 */
import '../css/main.scss';

/*
 * Image imports
 */
import '../img/blb-favicon-src.png';
import '../img/mag-favicon-src.png';
import '../img/honw-favicon-src.png';
import '../img/logo.svg';
import '../img/logo-blackboat.svg';
import '../img/logo-blackboat-typo.svg';
import '../img/honw-logo.svg';
import '../img/mag-logo.svg';
import '../img/wnc-logo.svg';
import '../img/sfo-logo.svg';
import '../img/tnx-logo.svg';
import '../img/404.gif';
import '../img/chevron.svg';
import '../img/chevron-down.svg';
import '../img/close.svg';
import '../img/pattern.svg';

/*
 * Script imports
 */
import $ from 'jquery';
import 'lazysizes';
import AOS from 'aos';
//import barba from '@barba/core';

import header from './components/header';
import buildLoadingLayerController from './components/loading-layer';
import sendGoogleAnalyticsPageView from './components/analytics';
import initServiceWorker from './service-worker/sw-init';
import initialFunctions from './components/initialFunctions';
import socialFeed from "./components/socialfeed";
import botFrame from "./components/botFrame";
import botWidget from "./components/botWidget";

import carousel from './components/carousel';
import video from './components/video';
import timeline from './components/timeline';
import maps from './components/maps';

/******************************************
 * own functions
 *****************************************/


/******************************************
 * on first load
 *****************************************/

/* check formie  
  document.addEventListener('onFormieInit', (e) => {
    let Formie = e.detail.formie;
    console.log(Formie)
  });
*/

$(() => {
  header();
  //loaderReset();
  initialFunctions();
  botWidget();
  video();
  carousel();
  timeline();
  maps();

  if($("main").hasClass('bot')) {
    botFrame()
  }
  else {
    botWidget()
  }
  if($("main").data('barba-namespace') == "magHome") {
    socialFeed()
  }

  AOS.init({
    delay: 50,
    duration: 600,
    offset: 80,
    once: true,
    easing: 'ease-in-out'
  });
});

if (process.env.ENVIRONMENT !== 'dev') {
  initServiceWorker();
} else {
  // eslint-disable-next-line no-console
  console.log(
    `Skipping registration of service worker as application is running with ENVIRONMENT [${process.env.ENVIRONMENT}]`
  );
}

