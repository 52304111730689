// Integrate Bot Widget or iFrame from DocsBot


export default function botWidget() {
   
        const botWrapper = document.getElementById('docsbot-widget');
        const botStyle = `
        ::-webkit-scrollbar {
            width: 10px;
            height: 10px;
          }
          
          ::-webkit-scrollbar-track {
            background: transparent;
          }
          
          ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: #9035D6;
          }
          ::-webkit-scrollbar-corner {
            background: #111;
          }

        .docsbot-chat-inner-container {
            background-color: #111;
            border-radius: 0;
            border: none;
        }
       
        .docsbot-chat-bot-message {
            background-color: #111 !important;
            color: #fff !important;
            border-color: #9035D6;
            font-size: 16px;
            line-height: 1.6;
        }
        .docsbot-user-chat-message,
        .docsbot-chat-input {
            background-color: #111;
            color: #fff;
        }

        .docsbot-chat-input {
            font-size: 16px  !important;
            line-height: 1.6;
            min-height: 26px;
            background: transparent;
        }

        .docsbot-chat-input textarea {
            font-size: 16px;
        }
        .docsbot-user-chat-message {
            font-size: 16px;
            line-height: 1.6;
            border-color: #3F3be9;
        }
        .docsbot-chat-input-form {
            background-color: transparent;
        }
        .docsbot-chat-input-container {
            border: 1px solid #b93bc3;
            border-radius: 40px;
            width: calc(100% - 22px);
            margin: 10px;
        }
        .docsbot-chat-btn-send {
            fill: #fff !important;
            background-color: transparent;
        }
        .docsbot-chat-btn-send:disabled {
            fill: #666 !important;
            opacity: 1;
        }
        .docsbot-chat-bot-avatar {
            display: none;
        }
        .docsbot-chat-bot-message-support a {
            background: #111;
            border: 1px solid #3F3be9;
            border-radius: 30px;
            padding: 10px;
            color: #fff !important;
        }
        .docsbot-chat-bot-message-support a::after {
            display: none;
        }
        .docsbot-chat-bot-message-support a:hover {
            border: 1px solid #3F3be9;
            border-radius: 30px !important;
            background-color: #111 !important;

        }
        .docsbot-chat-suggested-questions-container button {
            background-color: #111 !important;
            border: 1px solid rgb(145, 145, 145) !important;
            font-size: 14px;
            color: #ccc !important;
            border-radius: 30px;
            padding: 15px;
        }
        .docsbot-chat-suggested-questions-container button:hover {
            color: #fff !important;
            border-color: #b93bc3 !important;
        }
        .docsbot-chat-suggested-questions-container span {
            color: #ccc !important;
        }
        `;


        if(botWrapper) {
            const botTeam = botWrapper.dataset.botTeam
            const botID = botWrapper.dataset.botId
            window.DocsBotAI=window.DocsBotAI||{},DocsBotAI.init=function(c){return new Promise(function(e,o){var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src="https://widget.docsbot.ai/chat.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n),t.addEventListener("load",function(){window.DocsBotAI.mount({id:c.id,supportCallback:c.supportCallback,identify:c.identify,options:c.options,signature:c.signature});var t;t=function(n){return new Promise(function(e){if(document.querySelector(n))return e(document.querySelector(n));var o=new MutationObserver(function(t){document.querySelector(n)&&(e(document.querySelector(n)),o.disconnect())});o.observe(document.body,{childList:!0,subtree:!0})})},t&&t("#docsbotai-root").then(e).catch(o)}),t.addEventListener("error",function(t){o(t.message)})})};
        
            DocsBotAI.init({id: botTeam+'/'+botID,
                options: {
                    customCSS: botStyle,
                },});
        }
    

  return Promise.resolve({
    destroy: () => {
        botWidgetInstances.forEach((instance) => instance.destroy(true, true));
        botWidgetInstances.length = 0;
    }
  });
}
