// Rewrite Juicer LinkedIn posts via Slack integration
import $ from 'jquery';

export default function socialFeed() {
  window.Juicer.initialize();
  const feed = document.getElementById('juicerFeed');
  const feedWrapper = $('#socialFeed');
  const mutationConfig = { attributes: true };

  const rewritePosts = function () {
   if($(feed).hasClass('loaded')) {
     const linkedInPosts = $('li.j-slack', feed);

     if (linkedInPosts.length) {
       linkedInPosts.each(function () {
         const $this = $(this);
         const postLinkWrapper = $('.j-message > p', $this).children('a').last();
         const postLink = postLinkWrapper.prop('href');
         const linkWrapper = $('.j-meta > nav > a', $this);
         const imageWrapper = $('.j-image', $this);
         const posterWrapper = $('.j-poster > a', $this);
         imageWrapper.attr('href', postLink);
         linkWrapper.attr('href', postLink);
         posterWrapper.attr('href', postLink);
         linkWrapper.removeClass('j-slack j-slack-text-hover').addClass('j-linkedin j-linkedin-text-hover');
         postLinkWrapper.hide();
       }).promise().done( feedWrapper.addClass('is-active'));

     }
     else {
       feedWrapper.addClass('is-active');
     }
   }

   let loadMoreBtn = $('a.j-paginate');
    loadMoreBtn.click(function () {
      const feedTimeOut = setTimeout(rewritePosts, 1000);
    });
  };

  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(rewritePosts);

// Start observing the target node for configured mutations
  observer.observe(feed, mutationConfig);

  const socialFeedInstances = [feed];


}
