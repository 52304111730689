/**
 * Video Integration
 */

import Plyr from 'plyr';
import $ from 'jquery';


export default function video() {

  function isInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)

    );
  }

  const videoInstances = [];
  const selectorVideo = $('.video__container');
  if (!selectorVideo.length) { return; }
  let autoplay = false;
  let videoLoop = {active: false};

  selectorVideo.each(function (index) {
    const $this = $(this);
    let playerWrapper = '';
    let playerId = '#' + $this.attr('id');
    let playerConst = [
      'play-large',
      'play',
      'progress',
      'current-time',
      'mute',
      'volume',
      'fullscreen'
    ];
    if ($this.hasClass('video__snippet')) {
      playerConst = ['play-large'];
    }
    if ($this.hasClass('video__loop')) {
      videoLoop = {active: true};
    }
    if ($this.hasClass('video__embed')) {
      playerConst = [
        'play-large',
        'play',
        'progress',
        'current-time',
        'mute',
        'volume',
        'captions',
        'fullscreen'
        //'settings',
      ];
    }

    videoInstances[index] = new Plyr(playerId, {
      controls: playerConst,
      loop: videoLoop,
      fullscreen: {
        enabled: true, fallback: true, iosNative: true, container: null
      }
    });

    videoInstances[index].on('ended', function() {
      this.classList.add('plyr--ended');
    });

    videoInstances[index].on('ready', function() {
      playerWrapper = this.parentNode.id;
      const currentVideoId = document.getElementById(playerWrapper)
      if(!currentVideoId) {
        return
      }
      const playsInView = currentVideoId.dataset.playsinview;
      
      const checkViewport = function () {
        if(isInViewport(currentVideoId)) {
          videoInstances[index].muted = true
          videoInstances[index].play()
        }
        else {
          videoInstances[index].pause()
        }
      }

      if (playsInView) {
        checkViewport()
        document.addEventListener('scroll', function () {
          checkViewport()
          }, {
              passive: true
        });
      }
      
    });
    videoInstances[index].on('playing', function() {
      this.classList.remove('plyr--ended');
    });
    
  });

}
