import $ from 'jquery';

/**
 * Timeline Functions
 */
export default function timeline() {
  const timelineSelectorItems = $('.timelineSelector__item');
  if (!timelineSelectorItems.length) { return; }
  const timelines = $('.timeline');

  const timelineSelector = $('.timelineSelector__wrapper');
  $('.timelineSelector__item').first().addClass('active');

  const closetimelineSelector = function () {
    timelineSelector.removeClass('active');
  };

  timelineSelectorItems.click(function (e) {
    e.preventDefault();
    const itemID = $(this).data('target');
    timelineSelectorItems.each(function(){
      $(this).removeClass('active');
    });
    timelines.each(function(){
      $(this).removeClass('active');
    });
    $('#'+itemID).addClass('active');
    $(this).addClass('active');
    console.log($(this).data('target'));
  });

  timelineSelector.click(function (e) {
    e.preventDefault();
    $(this).toggleClass('active');
  });

  $(document).keyup(function (e) {
    if (e.keyCode === 27) {
      closetimelineSelector();
    }
  });

}
