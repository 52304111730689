//////////////////////////////////////////////////////////////////
///// Map  //////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

import loadGoogleMapsApi from 'load-google-maps-api';

let googleMapsLoaderPromise;

const GOOGLE_MAPS_API_KEY = 'AIzaSyCWv78HL4VQhKfVeWQLCNxmnWCBOw5wzNc';

const HONW_COORDINATES = {
  lat: 53.548609715604165,
  lng: 9.94331667026842
};

const STYLED_MAP_TYPE_OPTIONS = [
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e9e9e9"
      },
      {
        "lightness": 17
      }
    ]
  },
  {
    "featureType": "landscape",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      },
      {
        "lightness": 20
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#ffffff"
      },
      {
        "lightness": 17
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#ffffff"
      },
      {
        "lightness": 29
      },
      {
        "weight": 0.2
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      },
      {
        "lightness": 18
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      },
      {
        "lightness": 16
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      },
      {
        "lightness": 21
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dedede"
      },
      {
        "lightness": 21
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "color": "#ffffff"
      },
      {
        "lightness": 16
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "saturation": 36
      },
      {
        "color": "#333333"
      },
      {
        "lightness": 40
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f2f2f2"
      },
      {
        "lightness": 19
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#fefefe"
      },
      {
        "lightness": 20
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#fefefe"
      },
      {
        "lightness": 17
      },
      {
        "weight": 1.2
      }
    ]
  }
];

export default function maps() {

  const mapID =  document.getElementById('map');
  if (!mapID) return;

  if (!googleMapsLoaderPromise) {
    googleMapsLoaderPromise = loadGoogleMapsApi({
      key: GOOGLE_MAPS_API_KEY
    });
  }

  googleMapsLoaderPromise.then(function (googleMaps) {
    const styledMapType = new googleMaps.StyledMapType(STYLED_MAP_TYPE_OPTIONS);

    const honwLogo = {
      // eslint-disable-next-line max-len
      path: 'M7.92 0h4.21v39.89H7.92zM0 12.63h4.21v18.83H0zM23.77 0h4.21v31.47h-4.21zM31.69 12.63h4.21v23.05h-4.21zM15.85 4.21h4.21v31.47h-4.21z',
      fillColor: '#00c794',
      strokeColor: '#00c794',
      fillOpacity: 1,
      scale: 0.75
    };

    const map = new googleMaps.Map(
      mapID, {
        zoom: 14,
        center: HONW_COORDINATES,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        mapTypeControlOptions: {
          mapTypeIds: ['roadmap', 'satellite', 'hybrid', 'terrain', 'styled_map']
        }
      });
    new googleMaps.Marker({position: HONW_COORDINATES, map: map, icon: honwLogo});
    //Associate the styled map with the MapTypeId and set it to display.
    map.mapTypes.set('styled_map', styledMapType);
    map.setMapTypeId('styled_map');
  }).catch(function (error) {
    // eslint-disable-next-line no-console
    console.error('Failed initializing Google Maps API.', error);
  });
}
